<template>
  <el-container>
    <el-aside width="200px" class="med-left-menu">
      <app-navmenuleftmedecin :idmed="idMed" />
    </el-aside>
    <el-container>
      <router-view />
    </el-container>
  </el-container>
</template>

<script>
import AppNavmenuleftmedecin from "@/components/rh/layout/AppNavmenuleftmedecin";

export default {
  components: { "app-navmenuleftmedecin": AppNavmenuleftmedecin },

  data() {
    return {
      //
    };
  },
  created() {
    //
  },
  computed: {
    idMed() {
      const idmed = String(this.$route.params.idmed);
      // importe dans Vuex (./resources/js/store/modules/medecin.js) avec Axios les données de la bd via l'API Laravel (app/http/Controllers/API/MedecinsController.php)
      // importe dans le component racine pour éviter de chaque fois recharger les données
      //this.$store.dispatch('medl/loadMedecin', this.idMed);
      this.$store.dispatch("medl/loadMedecin", idmed);

      return idmed;
    },
  },
};
</script>
