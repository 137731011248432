<template>
  <div>
    <div class="med-left-menu-top">
      <!-- Photo de profil -->
      <h2>{{ medData.prenom }} <br />{{ medData.nom }}</h2>
      <div class="photo-profil-uploader">
        <div>
          <img :src="photoProfilUrl" class="photo-profil" />
          <div class="photo-upload-actions">
            <!-- Photo upload -->
            <el-tooltip class="item" effect="light" placement="bottom">
              <div slot="content">
                Télécharger une photo de profil :
                <ul>
                  <li>format : JPG ou PNG</li>
                  <li>taille : max 300 Ko</li>
                  <li>dimensions : 120 x 160 px</li>
                </ul>
              </div>
              <div class="photo-upload-upload">
                <input
                  class="pp-input-file"
                  type="file"
                  ref="ppInput"
                  @change="uploadPhotoProfil"
                />
                <i class="el-icon-upload2"></i>
              </div>
            </el-tooltip>
            <!-- Photo delete -->
            <el-tooltip
              class="item"
              effect="light"
              placement="bottom"
              content="Supprimer la photo de profil"
            >
              <div
                class="photo-upload-delete"
                v-show="medData.photo != placeholder"
                @click="deletePhotoProfil"
              >
                <i class="el-icon-delete"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <!-- CV -->
      <div class="cv-uploader">
        <div class="cv-uploader-txt">CV</div>
        <!-- CV upload -->
        <el-tooltip class="item" effect="light" placement="bottom">
          <div slot="content">
            Télécharger un CV :
            <ul>
              <li>format : PDF</li>
              <li>taille : max 2 Mo</li>
            </ul>
          </div>
          <div class="cv-upload-upload" v-show="!cvUrl">
            <input
              class="cv-input-file"
              type="file"
              ref="cvInput"
              @change="uploadCV"
            />
            <i class="el-icon-upload2"></i>
          </div>
        </el-tooltip>
        <!-- CV open -->
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          content="Ouvrir le CV"
        >
          <div class="cv-upload-open" v-show="cvUrl">
            <a :href="cvUrl" target="_blank"><i class="el-icon-view"></i></a>
          </div>
        </el-tooltip>
        <!-- CV delete -->
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          content="Supprimer le CV"
        >
          <div class="cv-upload-delete" v-show="cvUrl" @click="deleteCV">
            <i class="el-icon-delete"></i>
          </div>
        </el-tooltip>
      </div>
    </div>
    <!-- menu médecin -->
    <el-menu
      :default-openeds="['4', '5']"
      :default-active="menuActiveIdx"
      @select="menuHandleSelect"
    >
      <el-menu-item index="1">
        <i class="el-icon-user"></i><span>Données personnelles</span>
      </el-menu-item>
      <el-menu-item index="2">
        <i class="el-icon-document"></i><span>Contrats</span>
      </el-menu-item>
      <el-menu-item index="6">
        <i class="el-icon-ship"></i><span>Parcours clinique</span>
      </el-menu-item>
      <el-menu-item index="3">
        <i class="el-icon-cold-drink"></i><span>Activités hors clinique</span>
      </el-menu-item>
      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-medal"></i><span>Formation</span>
        </template>
        <el-menu-item index="4-1">Prégraduée</el-menu-item>
        <el-menu-item index="4-2">Postgraduée</el-menu-item>
        <el-menu-item index="4-3">Continue</el-menu-item>
        <el-menu-item index="4-4">Thèse</el-menu-item>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">
          <i class="el-icon-guide"></i><span>Tutorats</span>
        </template>
        <el-menu-item index="5-1">Tuteurs</el-menu-item>
        <el-menu-item index="5-2" v-show="pupillesShow">Pupilles</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
import { objGetNested } from "@/fonctions";
export default {
  data() {
    return {
      FPFolder: process.env.VUE_APP_API_URL + "/rhfiles/photos_profil/",
      //CVFolder: process.env.VUE_APP_API_URL + "/rhfiles/cv/", (redirigé vers l'url de l'API dans route.js)
      CVFolder: "/rhfiles/cv/",
      placeholder: "profile_placeholder.png",
    };
  },
  props: {
    idmed: String,
  },
  created() {
    this.$store.commit("medl/resetMedecin");
  },
  computed: {
    medData() {
      return this.$store.state.medl.medecin;
    },
    medecinLoadStatus() {
      return this.$store.state.medl.medecinLoadStatus;
    },
    photoProfilUpLoadStatus() {
      return this.$store.state.medl.medecinPhotoUploadStatus;
    },
    photoProfilUrl() {
      //https://codingexplained.com/coding/front-end/vue-js/adding-getters-setters-computed-properties
      if (this.photoProfilUpLoadStatus == 3) {
        this.$message.error({
          message: "Echec de l'enregistrement de la photo !",
        });
      }
      const photo = this.medData.photo ? this.medData.photo : this.placeholder;
      return this.FPFolder + photo + "?rnd=" + Math.random();
      // ajoute '?rnd=' + Math.random() à l'URL pour changer le nom de l'image pour qu'elle soit rechargée
    },
    cvUpLoadStatus() {
      return this.$store.state.medl.medecinCvUploadStatus;
    },
    cvUrl() {
      if (this.cvUpLoadStatus == 3) {
        this.$message.error({ message: "Echec de l'enregistrement du CV !" });
      }
      return this.medData.cv
        ? this.CVFolder + this.medData.cv + "?rnd=" + Math.random()
        : null;
      // ajoute '?rnd=' + Math.random() à l'URL pour changer le nom du cv pour q'il soit chargé
    },
    menuActiveIdx() {
      const path = this.$route.path;
      if (/^\/medecins\/\d+\/$/.test(path)) {
        return "1";
      } else if (path.includes("/contrats")) {
        return "2";
      } else if (path.includes("/fpregrads")) {
        return "4-1";
      } else if (path.includes("/fpostgrads")) {
        return "4-2";
      } else if (path.includes("/fcontinues")) {
        return "4-3";
      } else if (path.includes("/theses")) {
        return "4-4";
      } else if (path.includes("/hcactivites")) {
        return "3";
      } else if (path.includes("/tuteurs")) {
        return "5-1";
      } else if (path.includes("/pupilles")) {
        return "5-2";
      } else if (path.includes("/parcourscliniques")) {
        return "6";
      } else {
        return "";
      }
    },
    pupillesShow() {
      // seulemenet si médecin cadre 'MC' ou montre si pas de contrat
      //const poste = this.medData.lastcontrat ? this.medData.lastcontrat.poste.poste : null;
      const poste = objGetNested(this.medData, ["lastcontrat_poste"]);
      return poste ? poste == "MC" : false;
    },
  },
  methods: {
    async uploadPhotoProfil(event) {
      var file = event.target.files[0]; // récupère l'objet 'fichier'
      // Vérifie le fichier importé ( png ou jpg, max 300 Ko et taille 120 X 160 px)
      const isFileTypeAllowed = ["image/png", "image/jpeg"].includes(file.type);
      const isMax300Ko = file.size <= 300000;
      let dimensionsOk = true;

      if (!isFileTypeAllowed) {
        this.$alert("L'image doit être au format JPEG ou PNG !", {
          confirmButtonText: "OK",
        });
      } else if (!isMax300Ko) {
        this.$alert("L'image ne doit pas excéder 300 Ko !", {
          confirmButtonText: "OK",
        });
      } else {
        // vérifie les dimensions de l'image
        dimensionsOk = await validateImageDimensions(file, 120, 160);
        if (!dimensionsOk) {
          this.$alert(
            `Les dimensions de l'image doivent être de 120 x 160 px !`,
            { confirmButtonText: "OK" }
          );
        } else {
          // si toutes les conditions sont remplies, upload le fichier
          this.$store.dispatch("medl/uploadMedecinPhoto", {
            file: file,
            id: this.idmed,
          });
          // reset le input file (autrement marche pas si on charge plusieurs fois le même fichier)
          this.$refs.ppInput.value = null;
        }
      }
    },
    deletePhotoProfil() {
      this.$confirm(
        "T'es vraiment sûr-e de vouloir supprimer la photo ?",
        "Attention !",
        {
          confirmButtonText: "Confirmer",
          cancelButtonText: "Annuler",
          type: "warning",
        }
      ).then(() => {
        this.$store.dispatch("medl/deleteMedecinPhoto", {
          id: this.idmed,
          placeholder: this.placeholder,
        });
      });
    },
    async uploadCV(event) {
      const file = event.target.files[0]; // récupère l'objet 'fichier'
      // Vérifie le fichier importé ( pdf, max 2Mo)
      const isPDF = file.type == "application/pdf";
      const isMaxSize = file.size <= 2097152; //(en octet, file <= 2Mo)
      if (!isPDF) {
        this.$alert("Le CV doit être au format PDF !", {
          confirmButtontext: "OK",
        });
      } else if (!isMaxSize) {
        this.$alert("Le fichier ne doit pas excéder 2 Mo !", {
          confirmButtonText: "Ok",
        });
      } else {
        // upload le fichier
        this.$store.dispatch("medl/uploadMedecinCV", {
          file: file,
          id: this.idmed,
        });
        // reset le input file (autrement marche pas si on charge plusieurs fois le même fichier)
        this.$refs.cvInput.value = null;
      }
    },
    deleteCV() {
      this.$confirm(
        "Là, tu vas supprimer définitivement ce CV !",
        "Attention !",
        {
          confirmButtonText: "Confirmer",
          cancelButtonText: "Annuler",
          type: "warning",
        }
      ).then(() => {
        this.$store.dispatch("medl/deleteMedecinCV", this.idmed);
      });
    },
    menuHandleSelect(key /*, keyPath*/) {
      if (key == "1") {
        this.$router.push({
          name: "donnees_perso",
          params: { idmed: this.idmed },
        });
      } else if (key == "2") {
        this.$router.push({
          name: "med_contrats",
          params: { idmed: this.idmed },
        });
      } else if (key == "4-1") {
        this.$router.push({
          name: "med_fpregrads",
          params: { idmed: this.idmed },
        });
      } else if (key == "4-2") {
        this.$router.push({
          name: "med_fpostgrads",
          params: { idmed: this.idmed },
        });
      } else if (key == "4-3") {
        this.$router.push({
          name: "med_fcontinues",
          params: { idmed: this.idmed },
        });
      } else if (key == "4-4") {
        this.$router.push({
          name: "med_theses",
          params: { idmed: this.idmed },
        });
      } else if (key == "3") {
        this.$router.push({
          name: "med_hcactivites",
          params: { idmed: this.idmed },
        });
      } else if (key == "5-1") {
        this.$router.push({
          name: "med_tuteurs",
          params: { idmed: this.idmed },
        });
      } else if (key == "5-2") {
        this.$router.push({
          name: "med_pupilles",
          params: { idmed: this.idmed },
        });
      } else if (key == "6") {
        this.$router.push({
          name: "med_parcourscliniques",
          params: { idmed: this.idmed },
        });
      }
    },
  },
};

////////// FUNCTIONS ///////////

function validateImageDimensions(file, width, height) {
  return new Promise((resolve, reject) => {
    let _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = function () {
      let valid = img.width === width && img.height === height;
      valid ? resolve() : reject();
    };
  }).then(
    () => {
      return true;
    },
    () => {
      return false;
    }
  );
}
</script>
